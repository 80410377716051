import { useCallback, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'
import { batch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import Spinner from '../../app/components/Spinner'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  outletsHasMoreSelector,
  outletsOffsetSelector,
  nearbyOutletsListSelector,
  outletListFetchPendingSelector,
  outletsFiltersLocationSearchTermSelector,
  outletsFiltersSelector,
} from '../store/outletsSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { fetchNearbyOutlets } from '../store/outletsThunks'
import useAppStore from '../../../store/hooks/useAppStore'
import HeadingText from '../../app/components/HeadingText'
import BodyText from '../../app/components/BodyText'
import Button from '../../app/components/Button'
import getOutletListQueryParams from '../utils/getOutletListQueryParams'
import usePageBottom from '../../app/hooks/usePageBottom'
import { GeoPosition } from '../../location/types'
import {
  outletsSetOutletsFilters,
  outletsSetVisibleOutletSearchModal,
} from '../store/outletsSlice'
import useGeocodeService from '../../location/hooks/useGeocodeService'
import { ScriptStatus } from '../../app/hooks/useScript'
import { OutletSearchModalType, OutletsListPageQuery } from '../types'
import EmptyPlaceholder from '../../app/components/emptyPlaceholder/EmptyPlaceholder'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'
import getOutletSectionList from '../utils/getOutletSectionList'
import Input from '../../app/components/Input'
import useCartDrawerOpen from '../../checkout/hooks/useCartDrawerOpen'
import { OutletsPaginationLimit } from '../constants'
import { AppTestIds } from '../../app/constants'

import OutletCard from './outletCard/OutletCard'
import OutletCardSkeleton from './outletCard/OutletCardSkeleton'
import GreetingUser from './greeting/GreetingUser'
import OutletListSkeleton from './OutletListSkeleton'
import NewOrder from './newOrder/NewOrder'
import styles from './OutletsList.module.scss'

const PAGE_BOTTOM_OFFSET = 300

export default function OutletsList() {
  const { t } = useTranslation('outlets')

  const store = useAppStore()

  const router = useRouter()

  const dispatch = useAppDispatch()

  const outlets = useAppSelector(nearbyOutletsListSelector)

  const hasMore = useAppSelector(outletsHasMoreSelector)

  const outletListFetchPending = useAppSelector(outletListFetchPendingSelector)

  const locationSearchTerm = useAppSelector(
    outletsFiltersLocationSearchTermSelector,
  )

  const { fetchGeocodeByPlaceId, scriptStatus } = useGeocodeService()

  const query = router.query as OutletsListPageQuery

  const [isLoading, setIsLoading] = useState(true)

  const searchInputRef = useRef<HTMLInputElement>(null)

  const isWhiteLabeled = isAppWhiteLabeled()

  const isCartDrawerVisible = useCartDrawerOpen()

  const handleLoadMoreClick = useCallback(() => {
    const offset = outletsOffsetSelector(store.getState())
    const outletsFilters = outletsFiltersSelector(store.getState())
    const params = getOutletListQueryParams(outletsFilters)

    dispatch(
      fetchNearbyOutlets({
        ...params,
        offset,
        limit: OutletsPaginationLimit.LoadMore,
      }),
    )
  }, [dispatch, store])

  const outletSections = getOutletSectionList(outlets, t)

  const handleOnEndReached = useCallback(() => {
    if (!outletListFetchPending && hasMore) {
      handleLoadMoreClick()
    }
  }, [handleLoadMoreClick, hasMore, outletListFetchPending])

  useEffect(() => {
    // When has search query
    async function handleSearchQuery(q?: string) {
      await dispatch(
        fetchNearbyOutlets({
          offset: 0,
          limit: OutletsPaginationLimit.Initial,
          searchTerm: q ?? '',
        }),
      ).unwrap()

      dispatch(outletsSetOutletsFilters({ searchTerm: q || '' }))
      setIsLoading(false)
    }

    if (!query.pl) {
      const { q } = query
      handleSearchQuery(q)
    }
  }, [dispatch, query])

  useEffect(() => {
    async function handlePlaceIdQuery(pl: string) {
      try {
        const geocode = await fetchGeocodeByPlaceId(pl)
        const geoPosition: GeoPosition = {
          // @ts-ignore - incorrect types from google.maps.GeocoderResult
          latitude: geocode.geometry.location.lat(),
          // @ts-ignore - incorrect types from google.maps.GeocoderResult
          longitude: geocode.geometry.location.lng(),
        }

        dispatch(
          outletsSetOutletsFilters({
            location: {
              ...geoPosition,
              visibleSearchTerm: geocode?.formatted_address,
            },
          }),
        )

        await dispatch(
          fetchNearbyOutlets({
            offset: 0,
            limit: OutletsPaginationLimit.Initial,
            geoPosition,
          }),
        ).unwrap()

        setIsLoading(false)
      } catch (err) {
        router.replace('/')
      }
    }

    if (query.pl && scriptStatus === ScriptStatus.Ready) {
      const { pl } = query
      handlePlaceIdQuery(pl)
    }
  }, [dispatch, fetchGeocodeByPlaceId, query, router, scriptStatus])

  usePageBottom({
    offset: PAGE_BOTTOM_OFFSET,
    onReachedBottom: handleOnEndReached,
  })

  function handleSearchAgainClick() {
    const outletsFilters = outletsFiltersSelector(store.getState())
    const isPlace = outletsFilters?.location

    dispatch(
      outletsSetVisibleOutletSearchModal(
        isPlace
          ? OutletSearchModalType.PlaceSearch
          : OutletSearchModalType.OutletSearch,
      ),
    )
  }

  function searchOutlets(searchTerm: string) {
    batch(() => {
      dispatch(
        fetchNearbyOutlets({
          offset: 0,
          limit: OutletsPaginationLimit.Initial,
          searchTerm,
          keepExisting: true,
        }),
      )
      dispatch(outletsSetOutletsFilters({ searchTerm }))
    })
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const searchTerm = event.target.value

    searchOutlets(searchTerm)
  }

  const debouncedHandleChange = useDebouncedCallback(handleChange, 500)

  function handleClearSearch() {
    searchOutlets('')
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
  }

  return (
    <div className={styles.wrapper}>
      {/* Left Container */}
      <div className={styles.leftContainer}>
        <div
          className={clsx(styles.container, {
            [styles.drawerOpen]: isCartDrawerVisible,
          })}
        >
          {isWhiteLabeled && (
            <>
              <GreetingUser />
              <Input
                leftAccessory={<SearchIcon />}
                rightAccessory={
                  outletListFetchPending ? <Spinner size="small" /> : undefined
                }
                containerClassName={clsx(styles.searchInputContainer, {
                  [styles.drawerOpen]: isCartDrawerVisible,
                })}
                placeholder={t('searchRestaurant')}
                onChange={debouncedHandleChange}
                clearable={!!searchInputRef.current?.value}
                rightAccessoryOnClick={handleClearSearch}
                ref={searchInputRef}
              />
            </>
          )}
          {!isWhiteLabeled && locationSearchTerm && (
            <section className={styles.headerSection}>
              <BodyText>{t('restaurantsNear')}</BodyText>
              <HeadingText as="h2">{locationSearchTerm}</HeadingText>
            </section>
          )}
          {outlets.length > 0 && (
            <>
              {outletSections.map(outletSection => (
                <section key={outletSection.title}>
                  {outletSection.title && (
                    <HeadingText as="h3">{outletSection.title}</HeadingText>
                  )}
                  <div
                    className={clsx(styles.gridContainer, {
                      [styles.wl]: isWhiteLabeled,
                      [styles.drawerOpen]: isCartDrawerVisible,
                    })}
                    data-testid={AppTestIds.Outlet.OutletCardListContainer}
                  >
                    {outletSection.data.map(outlet => (
                      <OutletCard outlet={outlet} key={outlet.id} />
                    ))}
                    {outletListFetchPending &&
                      new Array(4).fill(0).map((_, index) => (
                        <OutletCardSkeleton
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                        />
                      ))}
                  </div>
                </section>
              ))}
              {hasMore && !outletListFetchPending && (
                <section className={styles.footerSection}>
                  <Button
                    variant="secondary"
                    size="large"
                    disabled={outletListFetchPending}
                    onClick={handleLoadMoreClick}
                    outlined
                    data-testid={AppTestIds.Outlet.LoadMoreButton}
                  >
                    {t('loadMore')}
                  </Button>
                </section>
              )}
            </>
          )}

          {!(isLoading || outletListFetchPending) && outlets.length === 0 && (
            <EmptyPlaceholder
              title={t('noRestaurantsNearby')}
              message={t('noRestaurantsNearbyDescription')}
              footer={
                isWhiteLabeled ? undefined : (
                  <Button variant="secondary" onClick={handleSearchAgainClick}>
                    {t('searchAgain')}
                  </Button>
                )
              }
            />
          )}
        </div>
        {isLoading && outlets.length === 0 && (
          <OutletListSkeleton isPageSkeleton={false} />
        )}
      </div>
      {/* Right Container */}
      {isWhiteLabeled && (
        <div className={styles.rightContainer}>
          <NewOrder />
        </div>
      )}
    </div>
  )
}
